@import "../../../utilities/mixins";
@import "../../../utilities/colors";

.sis-banner-section {
  padding-top: 7em;
  background-color: $COLOR_BACKGROUND_GRAY;
  width: 100%;
  height: auto;
  position: relative;

  &-text {
    position: relative;
    top: 0;
    left: 0;
    padding-bottom: 5em;
  }
}

.sis-banner-section::before {
  background: none;

  @include respond(lg) {
    content: "";
    position: absolute;
    top: 20%;
    left: 20%;
    right: 5%;
    bottom: 0px;
    background-image: url("../../../assets/images/sis_banner.png");
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-position: right bottom;
    z-index: 0;
  }
}
