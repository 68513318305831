.topic-header-container {
  height: 150px;

  .card-txt-container {
    margin-left: 1em;
  }

  .img-container {
    height: 5em;
    width: 5em;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
