@import "../../../../utilities/colors";

#item.active {
  background-color: $COLOR_MAIN_BLUE;
  border: $COLOR_MAIN_BLUE;
}

.body-1.color {
  color: $COLOR_BLACK;
}

#sinia-wui .list-group {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;

  &-item {
    border: none;
    border-top: 0.05em solid rgba(0, 0, 0, 0.125);
  }

  &-item:first-child {
    border: none;
    border-radius: 0;
  }

  &-item:last-child {
    border-bottom: none;
    border-radius: 0;
  }
}
