@import "../../../utilities/colors";

.switcher {
  &-select {
    border: none;
    cursor: pointer;
    color: $COLOR_GRAY_INPUT
  }

  &-select:focus {
    outline: 0;
  }
}
