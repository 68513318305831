@import "../../../utilities/colors";

.contact-image-background {
  width: 100%;
  height: 25em;
  margin-top: -8em;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header-custom {
  margin-top: -20.5em;

  h1,
  p {
    color: $COLOR_WHITE;
  }
}

.custom-blue-background {
  background-color: $COLOR_MAIN_BLUE;
  height: 25.5em;
  width: 100%;
}

.custom-yellow-background {
  background-color: $COLOR_YELLOW_BUTTON;
  height: 2em;
  width: 100%;
}
