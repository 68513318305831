.header-container {
  background-color: red;
}

#logo-minae {
  width: 200px;
  height: 80px;
}

#logo-sinia {
  width: 160px;
  height: 80px;
}
