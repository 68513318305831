@import "../../../../utilities/mixins";

.topic-card {
  &-img {
    width: 2em;
  }

  &-text {
    font-size: 12pt;
    hyphens: auto;
    line-height: 1em;
  }
}

.card-margin {
  @include respond(md) {
    margin-left: 1em;
  }
}

.category-container {
  overflow-x: auto;
}
