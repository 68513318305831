.tabs-padding {
  padding: 1em;
}

.tab-container {
  overflow-y: auto;
  overflow-x: auto;
}

div#tabs-container {
  width: 100%;
  height: 100%;
}

#tabs-container .tab-content {
  height: 100%;
}

.map-container {
  min-height: 500px;
  min-width: 300px;
}

#tabs-container .tab-content > .active {
  display: flex;
  flex-direction: column;
  height: 100%;
}
