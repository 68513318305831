@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap");

body,
html {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h5 {
  font-weight: 700;
}

h6 {
  font-weight: 600;
}
