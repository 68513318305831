@import "../../../utilities/colors.scss";

.footer-container {
  color: $COLOR_WHITE;
  background-color: $COLOR_MAIN_FOOTER_2;

  .footer-sub-container {
    background-color: $COLOR_MAIN_SUB_FOOTER_2;

    h6 {
      color: $COLOR_BLACK;
    }
  }
  h3 {
    color: $COLOR_MAIN_BLUE;
  }
  label {
    color: $COLOR_BLACK;
  }
  i {
    color: $COLOR_BLACK;
  }
}

.cr-logo {
  width: 23.5em;
  height: auto;
}

#sinia-wui .footer-link {
  color: $COLOR_BLACK;
  padding: 0.5em 0;

  &:hover {
    color: $COLOR_BLACK;
  }
}
