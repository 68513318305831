@import "../../../utilities/colors.scss";
@import "../../../utilities/mixins.scss";

#sinia-wui .body-card {
  margin: 0;
  padding: 0;
}

.topic-tree-container {
  @include respond(md) {
    width: 35%;
    height: 100%;
  }

  width: 100%;
}

.topic-tree-tabs {
  @include respond(md) {
    width: 65%;
  }

  width: 100%;
  overflow-y: auto;
}

.no-margin {
  margin: 0;
  padding: 0;
}

.loading-container {
  position: absolute;
  background-color: red;
  opacity: 0.7;
  z-index: 1000;
}

.data-container {
  width: 98%;
}
