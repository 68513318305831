@import "../../../../utilities/colors";
@import "../../../../utilities/mixins";

.statistics-sahm {
  background-color: $COLOR_BACKGROUND_GRAY;

  &-img {
    @include respond(lg) {
      display: inline;
      width: auto;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    display: none;
  }
}
