@import "../../../../utilities/colors.scss";

.topic-tree-container-margin {
  margin-left: 1.6em;
}

.topic-tree-container-stepper {
  border-radius: 100%;
  margin-right: 0.7em;
  text-align: center;
  color: $COLOR_WHITE;
  width: 1.2em;
  height: 1.2em;
  background-color: $COLOR_MAIN_BLUE;
}
