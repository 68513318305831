@import "../../../utilities/colors";

.bi.bi-plus {
  font-size: 2em;
  font-weight: 600;
}

.body-2 {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.1875em;
  letter-spacing: 0.025em;
  text-align: left;
}

.body-1 {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.05em;
  text-align: left;
  color: $COLOR_GRAY_INPUT;
}

.clickable {
  cursor: pointer;
}
