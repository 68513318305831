.doc-frame {
  height: 100%;
  width: 100%;

  iframe {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    box-sizing: border-box;
  }
}

.doc-container-img {
  img {
    height: auto;
    width: 100%;
  }
}
