@import "../../../utilities/colors.scss";
@import "../../../utilities/mixins";

.topic-card-container {
  margin-right: 0.2em;
  width: 10%;
}

.banner-img-container {
  position: relative;
  height: 400px;
}

.banner-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.banner-content {
  .banner-text {
    width: 100%;
  }

  position: relative;
  color: $COLOR_WHITE;
  z-index: 1;

  @include respond(md) {
    height: auto;

    .banner-text {
      width: 50%;
    }
  }
}

.banner-bar {
  background-color: $COLOR_MAIN_BLUE;
  height: 1.8em;
}

.background-color {
  background-color: $COLOR_BACKGROUND_GRAY;
}

.feature-section-container {
  position: relative;
  height: auto;
  z-index: 3;
  top: -5em;
}

.dark-background {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.4);
}
