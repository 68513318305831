@import "../../../../utilities/mixins";
@import "../../../../utilities/colors";

.about-banner-section {
  background-color: $COLOR_BACKGROUND_GRAY;

  &-background {
    width: 100%;
    height: 30em;

    img {
      @include respond(lg) {
        width: auto;
        height: 100%;
        object-fit: contain;
        object-position: 100% 0;
        display: inline;
        mix-blend-mode: darken;
        border-radius: 625em 0 0 625em;
      }

      display: none;
    }
  }

  &-text {
    margin-top: -23em;
  }
  
}
