$COLOR_GRAY_INPUT: #6c757d;
$COLOR_WHITE: #ffffff;
$COLOR_BLACK: #000000;
$COLOR_MAIN_BLUE: #2b6e99;
$COLOR_BACKGROUND_GRAY: #f8f8f8;
$COLOR_YELLOW_BUTTON: #fcb712;
$COLOR_YELLOW_BUTTON_HOVER: #ffcc4c;
$COLOR_MAIN_FOOTER: #253d51;
$COLOR_MAIN_SUB_FOOTER: #142b39;
$COLOR_MAIN_FOOTER_2: #dadada;
$COLOR_MAIN_SUB_FOOTER_2: #b8b8b8;
$COLOR_FACEBOOK: #4267b2;
$COLOR_TWITTER: #1da1f2;
$COLOR_SECONDARY_GREEN: #16b14f;
$COLOR_TABLE_HEADER: #537644;
$COLOR_ERROR_MESSAGE: #e30e0e;
