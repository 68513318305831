@import "../../../../utilities/colors";

.sis-list {
  &-item {
    &-open {
      background-color: #f4f4f4;
    }
  }

  &-item:hover {
    background-color: #f4f4f4;
  }

  &-doc:hover {
    background-color: #e0e0e0 !important;
  }

  .bi {
    color: $COLOR_MAIN_BLUE;
    -webkit-text-stroke: 1px;
  }
}

.break-file-name {
  width: 100%;
  word-break: break-all;
  white-space: normal;
}
