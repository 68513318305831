@import "../../../utilities/colors.scss";


.card-icon {
  width: 50%;
}

.card-container{
  background-color: red;
  border: solid;
}
