@import "../../../utilities/colors.scss";
@import "../../../utilities/mixins.scss";

.col-container {
  @include respond(md) {
    width: 30%;
    height: 100%;
  }
  width: 100%;
}

.doc {
  @include respond(md) {
    width: 70%;
  }

  width: 100%;
  height: 100%;

  &-container {
    flex-grow: 1;
  }
}

.no-margin {
  margin: 0;
  padding: 0;
}

.sis-container {
  height: 100vh !important;

  &-body {
    height: 100vh;
  }
}

.full-btn:hover {
  background-color: $COLOR_MAIN_FOOTER_2;
}

.sis-img-container {
  height: auto;
  width: 10em;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
