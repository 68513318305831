@import "../../../../utilities/colors";

.contact-info-icon-size {
  font-size: 2.5em;
}

.bi-twitter {
  color: $COLOR_TWITTER;
}

.bi-facebook {
  color: $COLOR_FACEBOOK;
}
