@import "../../../../utilities/colors.scss";
@import "../../../../utilities/mixins.scss";

.main-geo-section {
  background-color: $COLOR_MAIN_BLUE;
  border-radius: 20px;
  color: $COLOR_WHITE;
}

.geo-divider {
  border-top: 0.4em solid $COLOR_WHITE;
  width: 100px;
  border-radius: 10px;
  opacity: 1;
}

