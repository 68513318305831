@import "../../../../utilities/colors";

#sinia-wui .error-message {
  color: $COLOR_ERROR_MESSAGE;
}

.send-message {
  padding: 0.5em;
  border-radius: 0.25em;
  border: 0.1em dashed $COLOR_SECONDARY_GREEN;
  background-color: #e6ffe6;
}
