.error-container {
  border: 1px solid red;
  background-color: rgba($color: red, $alpha: 0.05);
  border-radius: 0.25em;
  margin: 3em;
  padding: 1em 3em;

  span {
    font-weight: 600;
  }

  .icon {
    color: red;
  }
}
