@import "../../../utilities/colors";

.loader-container {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

$spinner-color: $COLOR_YELLOW_BUTTON;
$spinner-size: 25em;
$spinner-width: 0.8em;
$spinner-time: 1.7s;
$logo-size: 8em;

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: $spinner-size;
  height: $spinner-size;

  &-logo {
    height: $logo-size;
    width: auto;
  }

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $spinner-width solid $spinner-color;
    border-top-color: white;
    border-bottom-color: white;
    animation: spin $spinner-time linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
