@import "../../../../utilities/colors.scss";

#sinia-wui .custom-primary-button {
  background-color: $COLOR_YELLOW_BUTTON;
  border-radius: 50px;
  border-color: $COLOR_YELLOW_BUTTON;
  color: $COLOR_BLACK;
  text-transform: uppercase;
  font-weight: 700;
}

#sinia-wui .custom-primary-button:hover {
  background-color: $COLOR_YELLOW_BUTTON_HOVER;
  border-color: $COLOR_YELLOW_BUTTON_HOVER;
  color: $COLOR_BLACK;
}
