.line-chart {
  &-line {
    fill: none;
  }

  &-grid {
    opacity: 0.1;

    .tick text {
      display: none;
    }
  }

  &-focus {
    display: none;
  }
}
