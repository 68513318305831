@mixin respond($breakpoint) {
  // all mobile goes first above all other media queries
  // after -- all media queries go in ascending order

  @if ($breakpoint == sm) {
    @media (min-width: 36em) {
      // 576px
      @content;
    }
  }

  @if ($breakpoint == md) {
    @media (min-width: 48em) {
      // 768px
      @content;
    }
  }

  @if ($breakpoint == lg) {
    @media (min-width: 62em) {
      // 992px
      @content;
    }
  }

  @if ($breakpoint == xl) {
    @media (min-width: 75em) {
      // 1200px
      @content;
    }
  }

  @if ($breakpoint == xxl) {
    @media (min-width: 120em) {
      // 1920px
      @content;
    }
  }
  @if ($breakpoint == xxxl) {
    @media (min-resolution: 192dpi) and (min-width: 37.5em),
      (min-width: 125em) {
      // 2000px || high res 600px
      @content;
    }
  }
}

// flex

@mixin flex($direction, $justifyContent, $alignItems) {
  display: flex;

  @if $justifyContent {
    justify-content: $justifyContent;
  }
  @if $alignItems {
    align-items: $alignItems;
  }
  @if $direction {
    flex-direction: $direction;
  }
}

// pulse

@mixin pulse($type) {
  @if ($type == pulse-light) {
    @keyframes #{$type} {
      0% {
        opacity: 1;
      }
      25% {
        opacity: 0.8;
      }
      50% {
        opacity: 1;
      }
      75% {
        opacity: 0.8;
      }
      100% {
        opacity: 1;
      }
    }
  }
  @if ($type == pulse-full) {
    @keyframes #{$type} {
      0% {
        opacity: 0.7;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 0.7;
      }
    }
  }
}

// keyframe scroll indication
@mixin keys($type) {
  @if ($type == bounce) {
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    animation: bounce 3.6s ease infinite;
    transform-origin: 50% 50%;

    @keyframes #{$type} {
      0% {
        transform: translateY(0);
      }
      6% {
        transform: translateY(0);
      }
      12% {
        transform: translateY(0);
      }
      22% {
        transform: translateY(-15px);
      }
      28% {
        transform: translateY(0);
      }
      33% {
        transform: translateY(-15px);
      }
      44% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}
