@import "../../../utilities/colors";

.contact-info-card {
  &-size {
    font-size: 2em;
  }

  &-color {
    color: $COLOR_SECONDARY_GREEN;
  }
}
