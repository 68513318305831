@import "../../../../utilities/mixins";
@import "../../../../utilities/colors";

.statistics-description {
  background-color: $COLOR_BACKGROUND_GRAY;

  &-shadow {
    @include respond(lg) {
      box-shadow: 0 0.5em 1rem rgba(0, 0, 0, 0.15);
    }
  }

  &-background {
    width: 100%;
    height: 30em;

    img {
      @include respond(lg) {
        width: 50%;
        height: 100%;
        object-fit: cover;
        object-position: 0 100%;
        display: inline;
      }

      display: none;
    }
  }

  &-text {
    margin-top: -23em;
  }
}
