@import "../../../utilities/colors";

.breadcrumb-white {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $COLOR_WHITE;
    font-weight: 600;
    content: ">";
  }

  a {
    text-decoration: none;
    color: $COLOR_WHITE;
    font-weight: 600;
  }

  a:hover {
    color: $COLOR_YELLOW_BUTTON;
  }
}

.breadcrumb-black {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $COLOR_BLACK;
    font-weight: 600;
    content: ">";
  }

  a {
    text-decoration: none;
    color: $COLOR_BLACK;
    font-weight: 600;
  }

  a:hover {
    color: $COLOR_MAIN_BLUE;
  }
}
